






























import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";
import moment from "moment";

@Component
export default class QuestionList extends Vue {
  public list = [];
  public settings: Settings;
  public actualPage: number;
  public totalItems: number;
  public limit: number;

  public constructor() {
    super();
    this.list = [];
    this.actualPage = 1;
    this.totalItems = 1;
    this.limit = 12;
    this.settings = new Settings();
  }

  public transformDate(date) {
    return moment(date).lang("pl").format('LLL');
  }

  public loadQuestion(): void {
    this.axios.get(this.settings.settings.host + '/api/questions',{
      params: {
        page: this.actualPage.toString(),
        'order[date_add]': 'desc'
      },
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.totalItems = response.data['hydra:totalItems'];
      this.list = response.data['hydra:member'];
    });
  }

  created(): void {
      console.log('question list loaded');
      this.loadQuestion();
  }

}
